<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import util from "../../util/date";
import echarts from 'echarts'
import {GetPeopleProcessNumberByTodayLogData,GetPeopleTaskNumberByTodayLogData} from '../api/oa'
require('echarts/theme/macarons') // echarts theme
import resize from '../views/Dashboard/admin/components/mixins/resize'

const animationDuration = 2000

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '330px'
    },
    departid: {
       type: Array,
       default: []
    }
  },
  data() {
    return {
      lookdate:new Date(),
      chart: null,
      departusers: [],
      peoplecode: [],
      peoplecode2: [],
      listone: [],
      listtwo: [],
      listthree: [],
      listfour: [],
      listfive:[]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
    var lookdate=this.$parent.$parent.getLookDate();
    // console.log(yearVal)
    this.lookdate=lookdate;
    // console.log(util.formatDate.format(this.lookdate,'yyyy/MM/dd 00:00:00'))
    // console.log(this.lookdate);
    //console.log(this.year);
    var valuelist = new Array()
    // console.log(this.departid);
    this.departid.forEach(item => {
        valuelist.push(item.name);
        this.peoplecode.push(item.id);
        this.peoplecode2.push("BPM/"+item.username);
        if(this.peoplecode.length == 1){
          // GetPeopleTaskNumberByTodayLogData({peoples:this.peoplecode,date:util.formatDate.format(this.lookdate,'yyyy/MM/dd 00:00:00')}).then(res => {
          GetPeopleTaskNumberByTodayLogData({peoples:this.peoplecode,date:this.lookdate}).then(res => {
              this.listone = res.data.response[0];
              this.listtwo = res.data.response[1];
              this.listthree = res.data.response[2];

              // GetPeopleProcessNumberByTodayLogData({peoples:this.peoplecode2,date:util.formatDate.format(this.lookdate,'yyyy/MM/dd')}).then(res => {
              GetPeopleProcessNumberByTodayLogData({peoples:this.peoplecode2,date:this.lookdate}).then(res => {
                  this.listfour = res.data.response[0];
                  this.listfive = res.data.response[1];
                  this.initChart()
              })
          })
        }
    })
    this.departusers = valuelist;
    this.initChart()
            
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.chart.off('click')
      let self=this;
      this.chart.on('click', data=>{
        // console.log(data);
        var index=data.dataIndex;
        // console.log(this.peoplecode);
        var usercode=this.peoplecode[index];
        var username=this.peoplecode2[index];
        // console.log(this.peoplecode2)
        
        var arr=new Array();
        arr.push(usercode);
        arr.push(username);
        // console.log("obj")
        // console.log(arr)
        // console.log(usercode);
        // console.log(self.$parent);
        self.$emit('call',arr);
      });
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: 10,
          left: '2%',
          right: '2%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: this.departusers,
          axisTick: {
            alignWithLabel: true
          },
          axisLabel:{
            interval: 0,
    	 }
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: '创建任务',
          type: 'bar',
          stack: 'vistors',
          barWidth : 40,
          data: this.listone,
          animationDuration
        }, {
          name: '增加进度',
          type: 'bar',
          stack: 'vistors',
          barWidth : 40,
          data: this.listtwo,
          animationDuration
        }, {
          name: '完成任务',
          type: 'bar',
          stack: 'vistors',
          barWidth : 40,
          data: this.listthree,
          animationDuration
        }, {
          name: '平台流程发起数量',
          type: 'bar',
          stack: 'vistors',
          barWidth : 40,
          data: this.listfour,
          animationDuration
        }, {
          name: '平台流程处理数量',
          type: 'bar',
          stack: 'vistors',
          barWidth : 40,
          data: this.listfive,
          animationDuration
        }]
      });

      
    }
  }
}
</script>
